const alert = {
  MuiAlert: {
    styleOverrides: {
      root: {
        borderRadius: '13px',
        maxWidth: '593px',
        padding: '28px 35px',
        marginBottom: '15px',
        width: '100%',
        position: 'relative' as const,
        margin: '30px auto 0',
      },
      icon: {
        marginRight: '20px',
      },
      message: {
        fontSize: '16px',
        lineHeight: 1.21875,
        '> p ': {
          lineHeight: 'inherit',
          ':first-of-type': {
            marginTop: '0',
          },
        },
      },
      action: {
        position: 'absolute' as const,
        top: '6px',
        right: '6px',
        margin: '0',
        padding: '0',
      },
    },
  },
};

export default alert;
