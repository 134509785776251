import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import styles from './ErrorBoundary.module.scss';
import { Button, Typography } from '@weareopenr/component-library';

const ErrorPage = () => {
  return (
    <div className={styles['page-container']}>
      <Typography size="XXL" weight="semi-bold" color="secondary">
        Oops... Something went wrong
      </Typography>
      <div className={styles['button-container']}>
        <Button
          onClick={() => {
            document.location.pathname = '/';
          }}
          primary
          size="large"
          className={styles['button']}
        >
          Go to dashboard
        </Button>
      </div>
    </div>
  );
};
export const ErrorBoundary = ({ children }: { children: JSX.Element }) => {
  return <ReactErrorBoundary fallback={<ErrorPage />}>{children}</ReactErrorBoundary>;
};
