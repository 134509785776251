const colors: IColors = {
  error: '#B3261E',
  lightBlue: '#367BB0',
  darkBlue: '#010711',
  lightGrey: '#E8E9EB',
  lightestGrey: '#E5E5E5',
  navy: '#16243A',
  white: '#FFFFFF',
  black: '#000000',
  darkRed: '#B3261E',
  tomatoRed: '#CC0E0E',
  kale: '#2B6A6B',
  kaleGradient: 'rgba(43, 106, 107, 0.2)',
  broccoli: '#F2F8F8',
  paleBlue: '#4891C7',
  caviar: '#6A6A6A',
  deepBlue22: 'rgba(38, 61, 75, 0.22)',
  deepBlue33: 'rgba(38, 61, 75, 0.33)',
  deepBlue07: 'rgba(38, 61, 75, 0.0726)',
  AZT_Light: '#4891C7',
  AZT_Dark: '#16243A',
  AZT_Dark_Opaque: 'rgba(72, 145, 199, 0.33)',
  AZT_Darkset: '#010711',
  AZT_Dark_Gradient: '#263D4B',
  AZT_Faded: '#C6E7FF',
  AZT_Sky: '#E0F2FF',
  blueberry: '#312783',
} as const;

export interface IColors {
  error: string;
  lightBlue: string;
  darkBlue: string;
  lightestGrey: string;
  lightGrey: string;
  navy: string;
  white: string;
  black: string;
  darkRed: string;
  tomatoRed: string;
  kale: string;
  kaleGradient: string;
  broccoli: string;
  paleBlue: string;
  caviar: string;
  deepBlue22: string;
  deepBlue33: string;
  deepBlue07: string;
  AZT_Light: string;
  AZT_Dark: string;
  AZT_Darkset: string;
  AZT_Dark_Opaque: string;
  AZT_Dark_Gradient: string;
  AZT_Faded: string;
  AZT_Sky: string;
  blueberry: string;
}

export default colors;
