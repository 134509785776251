import { Suspense, lazy } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import '@weareopenr/component-library/build/global.scss';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import { NotificationContext } from '@weareopenr/component-library';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import { CircularProgress } from '@mui/material';
import { SpinnerContainer } from 'src/pages/Dashboard/Dashboard.styles';

const Welcome = lazy(() => import('src/pages/Welcome'));
const Layout = lazy(() => import('src/components/Layout/Layout'));
const NotFound = lazy(() => import('src/components/NotFound'));

declare global {
  interface Window {
    Cypress: Cypress.Cypress;
  }
}

const CustomLoginCallback = () => {
  const history = useHistory();
  return (
    <LoginCallback
      errorComponent={() => {
        history.push('/login');
        return null;
      }}
    />
  );
};

const App = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FlagsmithProvider
        options={{
          environmentID: process.env.REACT_APP_FLAGSMITH_ENV,
        }}
        flagsmith={flagsmith}
      >
        <NotificationContext>
          <Suspense
            fallback={
              <SpinnerContainer>
                <CircularProgress color="secondary" />
              </SpinnerContainer>
            }
          >
            <Switch>
              <Route exact path="/login" component={Welcome} />
              <Route path="/login/callback" component={CustomLoginCallback} />
              <SecureRoute path="/" component={Layout} />
              <Route component={NotFound} />
            </Switch>
          </Suspense>
        </NotificationContext>
      </FlagsmithProvider>
      <ScrollToTop />
    </LocalizationProvider>
  );
};

const AppOkta = () => {
  const history = useHistory();
  const oktaAuth = new OktaAuth({
    issuer: process.env.REACT_APP_OKTA_ISSUER,
    clientId: process.env.REACT_APP_OKTA_CLIENTID,
    redirectUri: window.location.origin + '/login/callback',
    postLogoutRedirectUri: window.location.origin,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
  });
  /* istanbul ignore next */
  const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri: string) => {
    const returnURL = originalUri || '/';
    history.replace(toRelativeUrl(returnURL, window.location.origin));
  };
  const onAuthRequired = () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      history.replace('/login');
    }
  };

  return window.Cypress ? (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <App />
    </Security>
  ) : (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri} onAuthRequired={onAuthRequired}>
      <App />
      <ReactQueryDevtools />
    </Security>
  );
};

export default AppOkta;
