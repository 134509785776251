import colors from '../colors';

const { white, tomatoRed, caviar } = colors;

const textfield = {
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        fontSize: '16px',
        lineHeight: '20px',
        fontWeight: 400,
        margin: '4px 0 0',
        color: caviar,
        '&.Mui-error': {
          color: tomatoRed,
        },
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        '&.Mui-error': {
          color: tomatoRed,
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        '&.Mui-error': {
          color: tomatoRed,
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: tomatoRed,
            '&:after': {
              content: '"!"',
              color: white,
              backgroundColor: tomatoRed,
              position: 'absolute' as const,
              right: '1em',
              top: '50%',
              transform: 'translateY(-60%)',
              display: 'flex',
              width: '20px',
              height: '20px',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '50%',
              fontSize: '80%',
            },
          },
        },
      },
    },
  },
};

export default textfield;
